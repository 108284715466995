import React, {useState, useContext} from "react"
import {EditorContext} from "../EditorContext"



export default function Editor(props) {

    const context = useContext(EditorContext)
    

    return (
        <div class="editor">
            <textarea value={context.editorField} onChange={context.changeContent} id="editor" />

        </div>
    )


}

