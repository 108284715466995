import React from "react"
import ReactDOM from "react-dom"
import {EditorContextProvider} from "./EditorContext"

import App from "./App"

ReactDOM.render(
    <EditorContextProvider>
     <App />
     </EditorContextProvider>
    , 
    document.getElementById("root")
)
