import React from "react"

import Editor from "./components/Editor"
import Previewer from "./components/Previewer"

function App() {
    
    return (
        
        
        <div class="main">
        <h1 class="headline">Markdown Previewer</h1>
            <Editor />
            <Previewer />
        </div>
        
    )
}

export default App