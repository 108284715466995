import React, {useState, useContext} from "react"
import {EditorContext} from "../EditorContext"
import {  MarkdownPreview  } from "react-marked-markdown";
import marked from 'marked';

marked.setOptions({
    breaks: true,
  });

export default function Previewer(props) {

    const context = useContext(EditorContext)
   
   
    
    return (

        <div class="previewer" id="preview" dangerouslySetInnerHTML={{ __html: marked(context.editorField, {sanitize: true}) }} />
        
         
   

        
    )


}


 /*  <MarkdownPreview
    value={context.editorField}
    markedOptions={{
      gfm: true,
      tables: true,
      breaks: true,
      pedantic: false,
      sanitize: true,
      smartLists: true,
      smartypants: false,
      langPrefix: "code"
     }} /> */